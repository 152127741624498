import React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";

export default function OfferPage({ data: { posts } }) {
  const allPosts = (posts && posts.nodes) || [];

  return (
    <Layout>
      <Container>
        <h1 className="text-3xl font-bold mb-3">Nasz Oferta</h1>
        {allPosts.map((post) => (
          <Link
            key={post.originalId}
            to={`/oferta/${post.slug}`}
            className="block hover:underline mb-1"
          >
            {post.title}
          </Link>
        ))}
      </Container>
    </Layout>
  );
}

export function Head() {
  return (
    <>
      <title>Olga Cieślik Oferta</title>
      <meta
        name="description"
        content="Oferta - Olga Cieślik Profesjonalny opiekun zwierząt"
      />
    </>
  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }

    posts: allDatoCmsPost {
      nodes {
        slug
        title
        content
        position
        originalId
      }
    }
  }
`;
